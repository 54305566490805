/******************************

General

*****************************/
.common_color {
    color: #0a1612;
    color: #f3d250;
    color: #BAC0CF;
    color: #6fd1cc;
    color: #dddddd;
    color: #ffccbc;
    box-shadow: 1px 2px 2px #9292925b;
    box-shadow: 5px 5px 25px #312f2f5b;
}
body{
    background-color: #ffe7d1;
    /* background-color: #ffebdb; */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
    -webkit-box-sizing: border-box;
    transition: all ease .25s;
}
h1, h2, h3, h4{
    font-family: 'Special Elite', cursive;
}

.body_wrapper {
    font-family: 'Economica', sans-serif;
    font-family: 'Josefin Sans', sans-serif;
    color: #396362;
    font-size: 18px;
    position: relative;
}
.wrapper {
    width: 90%;
    max-width: 1250px;
    margin: auto;
}
.container{
    width: 90%;
    max-width: 1250px;
    margin: auto;
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex_container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex_wrap {
    display: -webkit-flex; /* Safari */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    display: flex;   
    flex-wrap: wrap;
}
.body_wrapper a {
    color: #396362;
    text-decoration: none;
}
.active, .active:hover {
    color: #396362 !important;
    font-weight: bold;
}

.body_wrapper li {
    list-style: none;
    margin-left: 30px;
}
.body_wrapper ul.list_style_initial li {
    list-style-type: initial;
}
.body_wrapper img {
    max-width: 100%;
}
.clearfix:after {
    display: block;
    content: '';
    clear:both;
}
.hidden {
    display: none;
}
.btn{
    background: #ffd415;
    border: 1px solid #ffd415; 
    box-shadow: 1px 2px 2px rgba(49,47,47,.36);
    color: #333;
    padding: .5em 1.5em;
    margin: 0 25px;
    cursor: pointer;
    border-radius: 2px;
    font-weight: bold;
}
.btn:hover {
    background: #fff;
    border: 1px solid #fff; 
    box-shadow: 1px 2px 2px #9292925b;
    color: #d8b000;
    font-weight: bold;
}
.fa-2x {
    font-size: 1.8em !important;
    margin-top: .1em !important;
}
.clear-fix {
    clear: both;
    height: 1px;
}




/********************************************

				ANIMATIONS

********************************************/


@keyframes fadein{
	0%{
		opacity: 0;
		}
	100%{
		opacity: 1;
		}
}

.keyframes_fadein{
    animation: fadein 2s forwards 0s ease;
}


@keyframes slidein{
	0%{
        transform: translateX(-25%);
		opacity: 0;
		}
	100%{
        transform: rotate(xx) translateX(0);
		opacity: 1;
		}
}

.keyframes_slidein{
    animation: slidein 1s forwards 0s ease;
}

/* Modal */
.modal{
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(16, 15, 15, 0.76);
    transition: all .5s;
}
.modal_content {
    position: relative;
    padding: 2em 3em;
    border-radius: 2px;
    background: #fff;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px;
    width: 100%;
    max-width: 500px;
    text-align: center;
}
.modal_close{
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
}


/******************************

 Header - Navigation
 
******************************/

.navigation {
    width: 100%;
}
.navigation .flex {
    justify-content: flex-end;
}
.navigation .brand {
    width: 30%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.navigation .brand h1 {
    font-size: 2rem;
    color: #396362;
}
.navigation .brand h5 {
    margin-top: 10px;
    color: #6e6e6e;
}
.navigation .nav {
    width: 70%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.navigation .nav ul li {
    display: inline-block;
    padding: 4px .7rem;
}
.navigation .nav ul li a {
    text-decoration: none;
    color: #6e6e6e;
    transition: all 0s;
}
.navigation .nav ul.social li a {
    color: #6e6e6e;
    transition: all 0s;
}
.navigation .nav ul li a:hover {
    border-bottom: 2px solid #0a1612;
    color: #0a1612;
}    

.navigation_mobile{
    display: none;
}


/******************************

Main

******************************/

.main {
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: 100px;
}
.main .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.main .header_nav{
    margin-bottom: 50px;
}
.main .header_nav a:hover .profile{
    display: none;
}
.main .header_nav h1 {
    font-size: 4em;
}
.main .header_nav a{
    color: #BAC0CF;
}
.main .header_nav a:hover{
    text-decoration: underline;
    color: #0a1612;
}
.main .h1_mobile {
    display: none;
}


/******************************

 Home page 
 
 ******************************/
 .homepage{
     width: 100%;
 }

/* Layout page */


.layout {
    position: absolute;
    top: 0;
    z-index: -1;
}
.layout .container{
    max-width: 100%;
    width: 100%;
}
.layout .col_lg {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items:center;
    float: left;
}
.layout .col_sm {
    float: left;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.layout .col_sm>div:first-child {
    height: 60%;
    min-height: 400px;
    width: 100%;
}
.layout .col_sm>div:nth-child(2) {
    height: 40%;
    min-height: 200px;
    width: 100%;
    background: #396362;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layout .col_sm>div:nth-child(2) .brand{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* position: absolute;
    right: 3em;
    bottom: 2em; */
    margin-top: 35px;
}
.layout .col_sm>div:nth-child(2) .brand h1 a {
    color: #fff;
    font-size: 4rem;
}
.layout .col_sm>div:nth-child(2) .brand h5 {
    color: #fff;
}
@keyframes slideTopIn{
    0%{
        transform: translateY(-25%);
        opacity: 0;
        }
    100%{
        transform: translateY(-15%);
        opacity: 1;
        }
}
.layout .col_sm>div:last-child {
    height: 350px;
    width: 250px;
    position: absolute;
    top: 25%;
    animation: slideTopIn 1s forwards .2s linear;
    opacity: 0;
    /* box-shadow: 2px 3px 10px rgba(128, 128, 128, 0.47); */
}
@keyframes fadeIn{
    0%{
        opacity: 0;
        }
    100%{
        opacity: 1;
        }
}
.layout .col_lg .frame{
    height: 100vh;
    animation: fadeIn .5s forwards 0s linear;
}
.layout .frame {
    background-size: cover;
    background-position: center;
    width: 100%;
    /* cursor: pointer; */
    position: relative;
    border-radius: 3px;
}
.layout .frame_3 {
    display: none;
}
.layout .frame a{
    position: absolute;
    bottom: 4em;
    border: none;
    border-radius: 0;
    box-shadow: none;
    transform: rotate(-90deg);
    /* Legacy vendor prefixes that you probably don't need... */

    /* Safari */
    -webkit-transform: rotate(-90deg);

    /* Firefox */
    -moz-transform: rotate(-90deg);

    /* IE */
    -ms-transform: rotate(-90deg);

    /* Opera */
    -o-transform: rotate(-90deg);

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.layout .frame a span{
    transition: all 0s;
}
.layout .frame a:hover {
   color: #396362;
   transform: rotate(0deg);
    /* Legacy vendor prefixes that you probably don't need... */

    /* Safari */
    -webkit-transform: rotate(0deg);

    /* Firefox */
    -moz-transform: rotate(0deg);

    /* IE */
    -ms-transform: rotate(0deg);

    /* Opera */
    -o-transform: rotate(0deg);
}
.layout h4 {
    font-size: 21px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    text-transform: uppercase;
    opacity: 0;
    width: 100%;
    padding: .8em 1em;
    background: #20202087;
}
.layout .frame:hover h4{
   opacity: 1;
}


.layout .modal_content{
   background: #d3d3d3;
}


.layout .bg{
    width: calc(100% - 10px);
    margin: 5px;
    height: 160px;
    background-size: cover;
    background-position: center;
    position: relative;
}
.see_more{
    display: none;
}

/*************************
        ALBUMS - Travel page
************************/
.albums .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.albums .content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.albums .item{
    width: 32%;
    margin: 5px;
    background-color: #fff;
    padding: 11px 15px 100px 15px;
    border-radius: 2px;
}
.albums .bg{
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    position: relative;
    filter: grayscale(1);
}
.albums .bg a{
    position: absolute;
    width: 100%;
    height: 100%;
}
.albums .text{
    position: absolute;
    top: 315px;
}
.albums h4.title{
    text-transform: capitalize;
    margin: 0 25px;
    color: rgb(78, 78, 78);
    font-size: 1.3rem;
    font-weight: bold;
    font-family: 'Caveat', cursive;
}
.albums .item:hover .bg,
.albums .item:hover .bg.add{  
    filter: grayscale(0);
}
.albums .item:hover h4.title{
    color: #0a1612;
}
.albums .item p {
    margin: 0px 25px 5px;
    font-size: 1rem;
    font-family: 'Caveat', cursive;
}
.albums .bg.add{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #BAC0CF;
    border-radius: 2px;
}
.albums a{
    text-decoration: none;
}
.albums .heading h1{
    font-size: 2.2rem;
    margin-bottom: 15px;
}
.albums .container:first-child{
    justify-content: flex-start;
    position: absolute;
    top: 0;
    align-items: center;
    height: 90px;
    width: 50%;
}
.albums .brand{
    z-index: 9;
    margin-left: 11%;
}



/******************************

 Single Album
 
 ******************************/
 .singleAlbum .container:first-child{
    justify-content: flex-start;
    position: absolute;
    top: 20px;
    align-items: center;
    height: 90px;
    width: 50%;
}
.singleAlbum .brand{
    z-index: 9;
    margin-left: 11%;
}
.singleAlbum img{
    /* height: 79vh; */
}
.singleAlbum .container{
    max-width: 1550px;
}
.singleAlbum .content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.singleAlbum .itemPhoto{
    margin: 0 3px;
    cursor: pointer;
}
.singleAlbum .bg{
    width: 70%;
    margin: 5px;
    height: 325px;
    background-size: cover;
    background-position: center;
    position: relative;
}
.singleAlbum h4.title{
    text-transform: capitalize;
    margin: 0 25px;
    color: rgb(78, 78, 78);
    font-size: 1.5rem;
    font-weight: bold;
}
.singleAlbum .item:hover .bg,
.singleAlbum .item:hover .bg.add{
    filter: brightness(.6);
}
.singleAlbum .item:hover h4.title{
    color: #0a1612;
}
.singleAlbum .item p {
    margin: 15px 25px;
}
.singleAlbum a{
    text-decoration: none;
}
.singleAlbum .heading{
    max-width: 820px;
    margin: 35px auto;
}
.singleAlbum .heading h1{
    font-size: 2.2rem;
}

.singleAlbum div:last-child>div:last-child{
    justify-content: space-evenly;
}

.singleAlbum img{
    height: 350px;
    /* object-fit: cover;
    object-position: center; */
    /* width: 32%; */
    margin: 10px !important;
    outline: 7px solid #fff;
}

/******************************

About
 
 ******************************/
 .about .container{
     display: flex;
     justify-content: flex-end;
 }
 .about .container>div{
     width: 50%;
 }
 
 .about .bg{
    background-size: cover;
    background-position: center;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
 }
 @keyframes slideRightIn{
    0%{
        transform: translateX(30%);
        opacity: 0;
        }
    100%{
        transform: translateY(0%);
        opacity: 1;
        }
}
    
 .about .text{
    transform: translateX(30%);
    animation: slideRightIn .8s forwards 0s linear;
    background: #fff;
    padding: 2em 5em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 11;
    height: 60vh;
    margin-right: 10%;
    margin-top: 4%;
 }
 .about .text h1,
 .about .text p{
    font-family: 'Caveat', cursive;
    margin: 10px 0;
 }
 .about .text a{
    text-decoration: underline;
 }
 .about .container:first-child{
    justify-content: flex-start;
    position: absolute;
    top: 0;
    align-items: center;
    height: 90px;
    width: 50%;
}
 .about .brand{
     z-index: 9;
     margin-left: 11%;
 }
 .about .brand h1 a{
    text-shadow: 1px 1px 7px #fff;
 }
 .about .brand h5{
    text-shadow: 1px 1px 7px #fff;
 }

/******************************

 Footer 
 
 ******************************/

.footer {
    height: 50px;
    width: 100%;
    margin-top: 15px;
}

/******************************

Contact

******************************/
.contact .container:first-child{
    justify-content: flex-start;
    position: absolute;
    top: 19px;
    align-items: center;
    height: 90px;
    width: 50%;
}
.contact .brand{
    z-index: 9;
    margin-left: 11%;
}
.form {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e7e7e7;
    width: 100%;
}
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 70%;
    padding: 50px 0;
}
.row {
    width: 100%;
    margin: 15px auto;
    display: flex;
    justify-content: center;
}
input, textarea {
    width: 100%;
    border: none;
    background-color: #fff;
    padding: 1.3em;
    border-radius: 3px;
    font-size: 16px;
}
input::placeholder, textarea::placeholder {
    color: #0a1612;
    padding-left: 10px;
    font-family: 'Economica', sans-serif;
    font-size: 16px;;
}
.btn_send {
    font-size: 1em;
}
.contact .info .headline {
    color: #0a1612;
    font-weight: bold;
    font-size: 2.2rem;
}





/* ********************************************

Responsive CSS 


*********************************************/
@media (max-width: 1150px){
    .navigation .nav ul li {
        padding: 4px .2rem;
    }
    .layout .col_sm>div:nth-child(2) .brand h1 a {
        font-size: 3rem;
    }
}
@media (max-width: 992px){

    .layout .col_sm>div:last-child {
        height: 280px;
        width: 190px;
        top: 30%;
    }
    .layout .col_sm>div:nth-child(2) .brand h1 a {
        font-size: 2.3rem;
    }

    .singleAlbum img{
        height: auto;
    }
    .singleAlbum .heading{
        max-width: 820px;
        margin: 0;
        padding: 75px 0 25px;
    }
    .singleAlbum .itemPhoto{
        margin: 5px 0;
    }

    .about .text {
        padding: 2em 2em;
        height: 65vh;
    }
    .about .brand {
        margin-left: 7%;
    }
    .about .brand h1 a {
        font-size: 1.8rem;
    }
    .about .brand h5 {
        font-size: .7rem;
    }
}


@media (max-width: 768px){
    .about .brand,
    .albums .brand,
    .contact .brand{
        display: none;
    }
    .flex {
        flex-direction: column;
    }
    .wrapper {
        width: 100%;
    }

    .navigation {
        display: none;
    }
    .navigation_mobile {
        width: 100%;
        display: block;
        z-index: 2;
        /* background-color: #fff; */
        position: fixed;
    }
    .navigation_mobile .brand {
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: column;
        background: #fff;
    }
    .navigation_mobile .brand h1 {
        margin: 10px 10px 5px;;
    }
    .navigation_mobile .brand h1 a{
        color: #396362;
        margin-left: 10px;
        font-size: 2rem;
    }
    .navigation_mobile .brand h5 {
        margin-left: 25px;
    }
    .navigation_mobile .nav_icon {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #0a1612;
    }
    .navigation_mobile .nav {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #fff;
        left:-900px;
    }
    .navigation_mobile .nav ul {
        display: flex;
        flex-direction: column;
    }
    .navigation_mobile .nav ul.social {
        flex-direction: row;
        justify-content: space-around;
        color: #0a1612;
    }

    .navigation_mobile .nav ul li {
        display: inline-block;
        padding: 1em;
        font-size: 1.3em;    
    }
    .navigation_mobile .nav ul li a {
        text-decoration: none;
        color: #0a1612;
    }
    .navigation_mobile .nav ul li a:hover {
        border-bottom: none;
    }  
    .navigation_mobile .nav ul li a.active {
        color: #0a1612;
        text-decoration: underline;
    }
    .navigation_mobile .nav.animated {
        left: 0;
        z-index: 99;
    }

    /* Layout */
    .layout .col_sm>div:nth-child(2) .brand {
       display: none;
    }
    .layout .col_lg .frame {
        height: 60vh;
    }
    .layout .col_lg {
        width: 60%;
        float: none;
        position: absolute;
        right: 0;
        top: 10%;
    }
    .layout .col_sm>div:last-child {
        height: 50vh;
        width: 43%;
        top: 45%;
        left: 5%;
    }
    .layout .col_sm>div:nth-child(2) {
        height: 20%;
        width: 72%;
        background: #396362;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .layout .frame a {
        bottom: -1em;
        right: 0;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    .layout .frame_3{
        display: block;
        height: 185px;
        width: 45%;
        position: absolute;
        bottom: 0;
        right: 0;
    }


    /* Contact */

    .contact {
        margin-top: 0;
    }
    .contact .content{
        width: 90%;
        margin: 100px auto 0;
    
    }
    .contact .header_nav{
        margin-bottom: 0;
    }
    .contact .header_nav h1 {
        font-size: 1.8em;
        margin: 0;
    }
    .contact .copy {
        margin: 1em auto;
    }
    .form {
        background: #fff;
    }
    .card {
        width: 95%;
    }
    input, textarea {
        background-color: #f5f5f5;
    }

    .footer {
        position: static;
    }

    .flex_container {
        flex-direction: column;
    }

    .albums .item:first-child{
        margin-top: 80px;
    }

    .albums .item{
        width: 100%;
        padding-bottom: 150px;
        margin: 0 0 10px;
    }
    .albums .bg{
        filter: grayscale(0);
    }
    .albums h4.title{
        padding: 15px 0 5px;
    }
    .albums .item p{
        margin: 0px 25px 15px;
    }

    .see_more{
        display: block;
        text-align: center;
        margin: 25px auto 10px;
        padding: 15px 25px;
        background: #396362;
        color: #fff !important;
        text-transform: uppercase;
        width: 160px;
    }

    /* About */
    .about {
        padding-top: 65px;
    }
    .about .bg{
        height: 85vh;
    }
    .about .text{
        padding: 1em;
        height: 80vh;
    }
    .about .bg {
        height: 100vh;
    }

    /* Single */
    .singleAlbum .container:first-child{
        display: none;
    }
}

@media (max-width: 579px){
    /* About */
    .about .container {
      flex-wrap: wrap;
      width: 100%;
    }
    .about .container>div{
        width: 100%;
        position: static;
    }
    .about .bg{
        height: 250px;
    }
    .about .container .text{
        padding: 1em 2em;
        margin: 0 auto;
        height: 100%;
        width: 90%;
        margin-top: -30px;
        z-index: 1;
    }

    /* Layout */
    .navigation_mobile .brand h1 {
        margin-bottom: 0;
    }
    .navigation_mobile .brand h1 a {
        font-size: 1.7rem;
    }
    .layout .col_lg {
        top: 13%;
    }
    .layout .col_lg .frame {
        height: 50vh;
    }
    .layout .col_sm>div:last-child {
        height: 37vh;
        width: 45%;
    }
    .layout .frame_3 {
        height: 20vh;
        width: 60%;
    }


    /* Single Album */
    .singleAlbum .heading h1{
        margin-top: 25px;
    }
}



@media (max-width: 450px){
    .singleAlbum img{
        width: 100%;
        margin: 25px 0px !important;
    }
    .singleAlbum div:last-child>div:last-child{
       display: block !important;
    }
    
}
